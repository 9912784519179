import React, { useEffect, useState } from "react";
import { useLoginContext } from "../context/login_context";
import { useNavigate, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Dialog from "./Dialog";
import commonUils from "../utils/common";

const Login = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const { showMessage, successMessage } = location.state || {
    showMessage: false,
    successMessage: "",
  };
  const {
    email,
    password,
    loggedIn,
    token,
    message,
    login,
    updateInputValue,
    resetMessage,
    updateCheckBox,
    setAuthToken,
  } = useLoginContext();

  const notify = (msg, isSuccess) => {
    if (isSuccess) {
      //toast.success(msg)
      const dateStr = commonUils.longDate(new Date());
      navigate(`/schedule#${dateStr}`);
    } else {
      toast.error(msg);
    }
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  const updateCheck = (e) => {
    setRememberMe(!rememberMe);
    updateCheckBox(!rememberMe);
  };

  useEffect(() => {
    const authToken = localStorage.getItem("sportixToken");
    if (authToken) {
      if (authToken !== "") {
        setAuthToken(authToken);
        const dateStr = commonUils.longDate(new Date());
        navigate(`/schedule#${dateStr}`);
      }
    }
    // Open the dialog when the component mounts (on window load)
    setIsDialogOpen(true);
  }, []); // Empty dependency array ensures this effect runs only once on mount

  useEffect(() => {
    // console.log(showMessage);
    if (showMessage) {
      toast.success(successMessage);
      //setShowMessage(false);
    }
  }, [showMessage, successMessage]);

  useEffect(() => {
    // console.log(loggedIn, message);
    if (!loggedIn && message !== "") {
      notify(message, loggedIn);
    } else if (loggedIn && message !== "") {
      //redirect to authnticated page...
      notify(message, loggedIn);
    }
    resetMessage();
  }, [loggedIn, message]);

  return (
    <div className="hero-text">
      <Dialog isOpen={isDialogOpen} onClose={closeDialog} />
      <ToastContainer hideProgressBar={true} theme="colored" />
      <section className="login">
        <div className="container">
          <form
            className="login-form"
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <h2>Login</h2>
            <div className="form-group">
              <label htmlFor="email">Email:</label>
              <input
                type="email"
                id="email"
                name="email"
                value={email}
                required
                autoFocus
                onChange={updateInputValue}
                className="input-field"
              />
            </div>
            <div className="form-group">
              <label htmlFor="password">Password:</label>
              <input
                type="password"
                id="password"
                name="password"
                value={password}
                required
                onChange={updateInputValue}
                className="input-field"
              />
            </div>
            <div className="form-group-alt">
              <input
                type="checkbox"
                id="rememberMe"
                name="rememberMe"
                checked={rememberMe}
                onChange={updateCheck}
                className="check-field"
              />
              &nbsp;&nbsp;
              <label htmlFor="password">Stay signed in</label>
            </div>
            <button onClick={login} className="btn large">
              SUBMIT
            </button>
          </form>
        </div>
      </section>
    </div>
  );
};

export default Login;
