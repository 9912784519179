export const UPDATE_LOGIN_VALUE = "UPDATE_LOGIN_VALUE";
export const LOGIN = "LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGIN_ERROR = "LOGIN_ERROR";

export const CALENDAR_LOADING = "CALENDAR_LOADING";
export const CALENDAR_TODAY = "CALENDAR_TODAY";
export const CALENDAR_NEXT = "CALENDAR_NEXT";
export const CALENDAR_PREV = "CALENDAR_PREV";

export const UPDATE_REGISTER_VALUE = "UPDATE_REGISTER_VALUE";
export const REGISTER = "REGISTER";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAILURE = "REGISTER_FAILURE";
export const REGISTER_ERROR = "REGISTER_ERROR";
export const RESET_REGISTER_STATE = "RESET_REGISTER_STATE";
export const REGISTER_STARTED = "REGISTER_STARTED";

export const RESET_LOGIN_MESSAGE = "RESET_LOGIN_MESSAGE";
export const RESET_REGISTER_MESSAGE = "RESET_REGISTER_MESSAGE";

export const SET_AUTH_TOKEN = "SET_AUTH_TOKEN";
export const BET_PLACED = "BET_PLACED";
export const SET_TEAMS = "SET_TEAMS";
export const SET_REMEMBER_ME = "SET_REMEMBER_ME";
