import React from "react";
import Topnav from "../components/Topnav";
import Hero from "../components/Hero";
import RegisterForm from "../components/RegisterForm";
import InfoCards from "../components/InfoCards";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";

const Register = () => {
  const location = useLocation();
  const { showMessage, isSuccess, message } = location.state || {
    showMessage: false,
    isSuccess: false,
    message: "",
  };

  const notify = (msg, isSuccess) => {
    if (isSuccess) {
      toast.success(msg);
    } else {
      toast.error(msg);
    }
  };

  // useEffect(() => {
  //   if (showMessage && message.trim() !== "") {
  //     notify(message, isSuccess);
  //   }
  // }, [showMessage, isSuccess, message]);

  const arrCards = [
    {
      id: 1,
      title: "Step 1",
      content: "Register with your email.",
      titleColor: "red",
    },
    {
      id: 2,
      title: "Step 2",
      content: "Predict the winner.",
      titleColor: "blue",
    },
    {
      id: 3,
      title: "Step 3",
      content: "Top the Chart",
      titleColor: "yellow",
    },
    {
      id: 4,
      title: "Step 4",
      content: "Win exiting prizes",
      titleColor: "pink",
    },
  ];
  return (
    <>
      <Topnav />
      <ToastContainer hideProgressBar={true} theme="colored" />
      <div className="hero">
        <RegisterForm />
        <InfoCards arrCards={arrCards} />
      </div>
    </>
  );
};

export default Register;
