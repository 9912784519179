import React, { useEffect } from "react";
import Topnav from "../components/Topnav";
import Hero from "../components/Hero";
import Login from "../components/Login";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation, useNavigate } from "react-router-dom";

const Home = () => {
  const location = useLocation();
  const { showMessage, successMessage } = location.state || {
    showMessage: false,
    successMessage: "",
  };

  useEffect(() => {
    if (showMessage && successMessage.trim() !== "") {
      notify(showMessage, successMessage);
    }
  }, [showMessage, successMessage]);

  const notify = (msg, isSuccess) => {
    if (isSuccess) {
      toast.success(msg);
    } else {
      toast.error(msg);
    }
  };
  return (
    <>
      <ToastContainer hideProgressBar={true} theme="colored" />
      <Topnav />
      <div className="hero">
        <Hero />
        <Login />
      </div>
    </>
  );
};

export default Home;
