import React from "react";

const ListMessage = ({ title, messageArr, className }) => {
  return (
    <div className={className}>
      <p className="infoMsg">{title}</p>
      <ol className="fld-msg-list">
        {messageArr.map((message) => {
          return <li key={message.id}>{message.text}</li>;
        })}
      </ol>
    </div>
  );
};

export default ListMessage;
