import React, { useEffect, useContext, useReducer, useState } from "react";
import reducer from "../reducers/login_reducer";
import axios from "axios";
import { apiserver } from "../utils/constants";

import {
  LOGIN,
  UPDATE_LOGIN_VALUE,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGIN_ERROR,
  RESET_LOGIN_MESSAGE,
  SET_AUTH_TOKEN,
  SET_TEAMS,
  SET_REMEMBER_ME,
} from "../actions";

const initialState = {
  email: "",
  password: "",
  loggedIn: false,
  token: "",
  message: "",
  authToken: "",
  teams: [],
  rememberMe: false,
};

const loginContext = React.createContext();

export const LoginProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const resetMessage = () => {
    dispatch({ type: RESET_LOGIN_MESSAGE, payload: { message: "" } });
  };

  const setAuthToken = (authToken) => {
    dispatch({ type: SET_AUTH_TOKEN, payload: { authToken } });
  };

  const updateCheckBox = (checkValue) => {
    dispatch({
      type: SET_REMEMBER_ME,
      payload: { rememberMe: checkValue },
    });
  };

  const setTeams = (teams) => {
    // console.log(teams);
    dispatch({ type: SET_TEAMS, payload: { teams } });
  };

  const updateInputValue = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    dispatch({ type: UPDATE_LOGIN_VALUE, payload: { name, value } });
  };

  const login = async () => {
    // console.log("logging in..");
    try {
      const url = `${apiserver}/api/v1/auth/login`;
      const response = await axios.post(url, {
        email: state.email,
        password: state.password,
      });
      const data = await response.data;
      if (response.status === 200) {
        // console.log("success");
        setAuthToken(data.token);
        if (state.rememberMe) {
          localStorage.setItem("sportixToken", data.token);
        } else {
          localStorage.setItem("sportixToken", "");
        }
        dispatch({
          type: LOGIN_SUCCESS,
          payload: {
            loggedIn: true,
            message: "login successfull",
            token: data.token,
            authToken: data.token,
          },
        });
      } else {
        // console.log(data.msg);
        dispatch({
          type: LOGIN_ERROR,
          payload: { loggedIn: false, message: data.msg },
        });
      }
    } catch (error) {
      console.log(error);
      console.log(error.message);
      if (error.code === "ERR_BAD_REQUEST") {
        const data = error.response.data;
        dispatch({
          type: LOGIN_ERROR,
          payload: { loggedIn: false, message: data.msg },
        });
      } else
        dispatch({
          type: LOGIN_ERROR,
          payload: { message: error.message, loggedIn: false },
        });
    }

    // dispatch({type: LOGIN})
  };

  return (
    <loginContext.Provider
      value={{
        ...state,
        updateInputValue,
        login,
        resetMessage,
        setAuthToken,
        setTeams,
        updateCheckBox,
      }}
    >
      {children}
    </loginContext.Provider>
  );
};

export const useLoginContext = () => {
  return useContext(loginContext);
};
