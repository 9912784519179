import React, { useEffect, useState } from "react";
import LeaderCard from "../components/LeaderCard";
import Topnav from "../components/Topnav";
import { getEvents, getLeaders } from "../utils/api";
import { useNavigate } from "react-router-dom";
import { useLoginContext } from "../context/login_context";

const Group = (props) => {
  const { group } = props;
  return (
    <div className="group-header-region">
      <h3>{group}</h3>
    </div>
  );
};
const Leaderboard = () => {
  const { authToken } = useLoginContext();
  const navigate = useNavigate();
  const [leaders, setLeaders] = useState([]);
  const [eventId, setEventId] = useState("");
  const [eventName, setEventName] = useState("");
  let rank = 1;
  let point = 0;
  useEffect(() => {
    // console.log(`authToken => ${authToken}`);
    if (authToken === "") {
      navigate("/");
    }
    const getOpenEvent = async () => {
      getEvents(authToken)
        .then((response) => {
          // console.log(response);
          const event_id = response.data.events[0]._id;
          setEventName(response.data.events[0].name);
          getLeaders(event_id, authToken)
            .then((response) => {
              // console.log(response.data.schedules);
              setLeaders(response.data.leaders);
            })
            .catch((error) => {
              console.log(error);
            });
          setEventId(event_id);
        })
        .catch((error) => {
          console.log(error);
          navigate("/");
        });
      // let response = await getEvents();
      // console.log(response);
    };
    getOpenEvent();
    // setBetsChanged(false);
  }, []);
  return (
    <>
      <section className="teamSection">
        <Topnav />
        <Group group={`${eventName} - LeaderBoard`} />

        <div className="leaders-title">
          <div className="member">
            <h4>Rank</h4>
          </div>
          <div className="member">
            <h4>Name</h4>
          </div>
          <div className="member">
            <h4>Points</h4>
          </div>
        </div>
        {leaders.map((member, index) => {
          if (index === 0) {
            point = member.point;
          }
          if (point > member.point && index >= 1) {
            point = member.point;
            rank++;
          } else {
            point = member.point;
          }
          return (
            <LeaderCard key={member._id} {...member} rank={rank}></LeaderCard>
          );
        })}
      </section>
    </>
  );
};

export default Leaderboard;
