import {
    CALENDAR_LOADING,
    CALENDAR_NEXT,
    CALENDAR_PREV,
    CALENDAR_TODAY

} from '../actions'

const dashboard_reducer = (state, action) => {
    if (action.type === CALENDAR_TODAY) {
        return {...state,curDate:new Date()}
    } if (action.type === CALENDAR_NEXT) {
        const {curDate} = action.payload
        return {...state,curDate: new Date(curDate.setMonth(curDate.getMonth()+1))}
    } if (action.type === CALENDAR_PREV) {
        const {curDate} = action.payload
        return {...state,curDate: new Date(curDate.setMonth(curDate.getMonth()-1))}
    }

    throw new Error(`No Matching "${action.type}" - action type`)
}

export default dashboard_reducer