import axios from "axios";
import { apiserver } from "./constants";
//import { useLoginContext } from "../context/login_context";

const instance = axios.create({
  baseURL: apiserver,
  // Other configurations...
});

//just for testing purpose
//const { authToken } = useLoginContext();

// Add a response interceptor to handle errors globally
instance.interceptors.response.use(
  (response) => {
    // If the response status code is within the range of 2xx, return the response
    if (response.status >= 200 && response.status <= 500) {
      return response;
    } else {
      // If the response status code is not within the range of 2xx, handle the error
      //throw new Error("Received non-success status code: " + response.status);
      return Promise.reject(response);
    }
  },
  (error) => {
    // Handle error responses
    if (error.response) {
      // The request was made and the server responded with a status code
      console.log("Error Response Data:", error.response.data);
      console.log("Error Response Status:", error.response.status);
      console.log("Error Response Headers:", error.response.headers);
      return Promise.reject(error.response);
    } else if (error.request) {
      // The request was made but no response was received
      console.log("No response received:", error.request);
    } else {
      // Something happened in setting up the request that triggered an error
      console.log("Error setting up the request:", error.message);
    }
    return Promise.reject(error);
  }
);
//config.chatApiServer

export const getEvents = async (authToken) => {
  return new Promise((resolve, reject) => {
    instance.defaults.headers.common["Authorization"] = `Bearer ${authToken}`;
    instance
      .get(`${apiserver}/api/v1/events`)
      .then((response) => {
        resolve({ status: response.status, data: response.data });
      })
      .catch((error) => {
        reject({ status: 401, error: error });
      });
  });
};

export const getSchedules = async (eventId, authToken) => {
  return new Promise((resolve, reject) => {
    instance.defaults.headers.common["Authorization"] = `Bearer ${authToken}`;
    instance
      .get(`${apiserver}/api/v1/schedules/event/${eventId}`)
      .then((response) => {
        resolve({ status: response.status, data: response.data });
      })
      .catch((error) => {
        reject({ status: 401, error: error });
      });
  });
};

export const getBets = async (eventId, authToken) => {
  return new Promise((resolve, reject) => {
    instance.defaults.headers.common["Authorization"] = `Bearer ${authToken}`;
    instance
      .get(`${apiserver}/api/v1/bets/event/${eventId}`)
      .then((response) => {
        resolve({ status: response.status, data: response.data });
      })
      .catch((error) => {
        reject({ status: 401, error: error });
      });
  });
};

export const getLeaders = async (eventId, authToken) => {
  return new Promise((resolve, reject) => {
    instance.defaults.headers.common["Authorization"] = `Bearer ${authToken}`;
    instance
      .get(`${apiserver}/api/v1/leaders/event/${eventId}`)
      .then((response) => {
        resolve({ status: response.status, data: response.data });
      })
      .catch((error) => {
        reject({ status: 401, error: error });
      });
  });
};

export const getTeams = async (eventId, authToken) => {
  return new Promise((resolve, reject) => {
    instance.defaults.headers.common["Authorization"] = `Bearer ${authToken}`;
    instance
      .get(`${apiserver}/api/v1/teams/event/${eventId}`)
      .then((response) => {
        resolve({ status: response.status, data: response.data });
      })
      .catch((error) => {
        reject({ status: 401, error: error });
      });
  });
};

export const addBet = async (eventId, winnerId, scheduleId, authToken) => {
  return new Promise((resolve, reject) => {
    instance.defaults.headers.common["Authorization"] = `Bearer ${authToken}`;
    instance
      .post(`${apiserver}/api/v1/bets`, { eventId, scheduleId, winnerId })
      .then((response) => {
        resolve({ status: response.status, data: response.data });
      })
      .catch((error) => {
        reject({ status: 401, error: error });
      });
  });
};

export const updatePoints = async (eventId, authToken) => {
  return new Promise((resolve, reject) => {
    instance.defaults.headers.common["Authorization"] = `Bearer ${authToken}`;
    instance
      .patch(`${apiserver}/api/v1/leaders/event/${eventId}`, {})
      .then((response) => {
        resolve({ status: response.status, msg: response.data.msg });
      })
      .catch((error) => {
        reject({ status: 401, error: error });
      });
  });
};
