import {
  REGISTER_STARTED,
  REGISTER,
  REGISTER_SUCCESS,
  REGISTER_ERROR,
  REGISTER_FAILURE,
  UPDATE_REGISTER_VALUE,
  RESET_REGISTER_STATE,
} from "../actions";

const register_reducer = (state, action) => {
  if (action.type === UPDATE_REGISTER_VALUE) {
    const { name, value } = action.payload;
    return { ...state, [name]: value };
  } else if (action.type === REGISTER_SUCCESS) {
    const { message, registered } = action.payload;
    // console.log(action.payload);
    return {
      ...state,
      message: message,
      registered: registered,
      register_started: false,
    };
  } else if (
    action.type === REGISTER_FAILURE ||
    action.type === REGISTER_ERROR
  ) {
    const { message, registered } = action.payload;
    return {
      ...state,
      message: message,
      registered: registered,
      register_started: false,
    };
  } else if (action.type === REGISTER_STARTED) {
    return { ...state, register_started: action.payload };
  } else if (action.type === RESET_REGISTER_STATE) {
    const { savedStatus, prof_error } = action.payload;
    return {
      ...state,
      savedStatus: savedStatus,
      prof_error: prof_error,
      register_started: false,
    };
  }
  throw new Error(`No Matching "${action.type}" - action type`);
};

export default register_reducer;
