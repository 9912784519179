import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useRegisterContext } from "../context/register_context";
import ListMessage from "./ListMessage";
import Spinner from "./Spinner";

const RegisterForm = () => {
  const [ismsg, setIsmsg] = useState(false);
  const navigate = useNavigate();
  const {
    firstName,
    lastName,
    email,
    password,
    password2,
    message,
    registered,
    updateInputValue,
    register,
    resetStateValues,
    register_started,
    setRegisterStarted,
  } = useRegisterContext();

  const [firstnameclass, setFirstnameclass] = useState("input-field");
  const [lastnameclass, setLastnameclass] = useState("input-field");
  const [emailclass, setEmailclass] = useState("input-field");
  const [passwordclass, setPasswordclass] = useState("input-field");
  const [password2class, setPassword2class] = useState("input-field");

  const [firstnameerr, setFirstnameerr] = useState("");
  const [emailerr, setEmailerr] = useState("");
  const [passworderr, setPassworderr] = useState("Password need to include..");
  const [password2err, setPassword2err] = useState("");
  const [showProgress, setShowProgress] = useState(false);

  const messageArr = [
    { id: 1, text: "At least seven characters" },
    { id: 2, text: "At least one lowercase letter" },
    { id: 3, text: "At least one uppercase letter" },
    { id: 4, text: "At least one number or symbol" },
  ];

  const notify = (msg, isSuccess) => {
    if (isSuccess) {
      resetStateValues();
      navigate("/", {
        state: {
          showMessage: true,
          successMessage: "Account created Successfully, Please login.",
        },
      });
    } else {
      toast.error(msg);
      //   console.log("error in register");
      //   navigate("/register", {
      //     state: {
      //       showMessage: true,
      //       isSuccess: false,
      //       message: msg,
      //     },
      //   });
      setIsmsg(true);
    }
  };

  useEffect(() => {
    // console.log("back to registration page");
    // const delay = setTimeout(() => {
    //   // Your code to execute after 2 seconds
    //   console.log("Delayed code executed after 3 seconds");
    // }, 3000);
    // console.log(`${registered} - ${message} - ${ismsg}`);
    if (ismsg) {
      if (registered && message !== "") {
        // console.log("true");
        notify(message, registered);
      } else if (message !== "") {
        notify(message, registered);
      }
      setIsmsg(false);
    } else if (registered && message !== "") {
      // console.log("true");
      notify(message, registered);
    }

    setShowProgress(register_started);
    // return () => clearTimeout(delay);
  }, [registered, message, ismsg]);

  const resetErr = () => {
    setFirstnameerr("");
    setEmailerr("");
    setPassworderr("");
    setPassword2err("");

    setFirstnameclass("input-field");
    setEmailclass("input-field");
    setPasswordclass("input-field");
    setPassword2class("input-field");
  };

  const isValidField = () => {
    let isValid = true;
    resetErr();
    if (document.getElementById("firstName").value.trim() === "" && isValid) {
      isValid = false;
      setFirstnameclass("fld-error");
      setFirstnameerr("First Name is required.");
    } else if (
      !document
        .getElementById("email")
        .value.trim()
        .match(/^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/) &&
      isValid
    ) {
      isValid = false;
      setEmailclass("fld-error");
      setEmailerr("Email is invalid.");
    } else if (
      document.getElementById("password").value.trim() === "" &&
      isValid
    ) {
      isValid = false;
      setPasswordclass("fld-error");
    } else if (
      !document
        .getElementById("password")
        .value.trim()
        .match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/) &&
      isValid
    ) {
      isValid = false;
      setPasswordclass("fld-error");
      setFirstnameerr(
        "Password is invalid, require Alphanumeric value with atleast one special character  "
      );
    } else if (
      document.getElementById("password").value.trim() !==
        document.getElementById("password2").value.trim() &&
      isValid
    ) {
      isValid = false;
      setPassword2class("fld-error");
    }
    return isValid;
  };

  const validateForm = (e) => {
    e.preventDefault();
    setShowProgress(register_started);
    setRegisterStarted(true);
    if (isValidField()) {
      setIsmsg(true);
      register();
    } else {
      setIsmsg(true);
      setShowProgress(false);
    }
  };
  return (
    <div className="hero-text">
      <ToastContainer hideProgressBar={register_started} theme="colored" />

      <section className="login">
        <Spinner visible={register_started} />
        <div className="container">
          <form
            className="login-form"
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <h2>Register</h2>
            <div className="form-group">
              <label htmlFor="firstName">First Name:</label>
              <input
                type="text"
                id="firstName"
                name="firstName"
                value={firstName}
                required
                autoFocus
                onChange={updateInputValue}
                className={firstnameclass}
              />
              <div className="fld-err">{firstnameerr}</div>
            </div>
            <div className="form-group">
              <label htmlFor="lastName">Last Name:</label>
              <input
                type="text"
                id="lastName"
                name="lastName"
                value={lastName}
                onChange={updateInputValue}
                className={lastnameclass}
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email:</label>
              <input
                type="email"
                id="email"
                name="email"
                value={email}
                required
                onChange={updateInputValue}
                className={emailclass}
              />
              <div>{emailerr}</div>
            </div>
            <div className="form-group">
              <label htmlFor="password">Password:</label>
              <input
                type="password"
                id="password"
                name="password"
                value={password}
                required
                onChange={updateInputValue}
                className={passwordclass}
              />
              <ListMessage
                title={passworderr}
                messageArr={messageArr}
                clsssName=""
              />
            </div>
            <div className="form-group">
              <label htmlFor="password2">Re-Enter Password:</label>
              <input
                type="password"
                id="password2"
                name="password2"
                value={password2}
                required
                onChange={updateInputValue}
                className={password2class}
              />
              <div>{password2err}</div>
            </div>
            <button onClick={validateForm} className="btn large">
              SUBMIT
            </button>
          </form>
        </div>
      </section>
    </div>
  );
};

export default RegisterForm;
