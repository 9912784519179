import React from "react";
import { useEffect } from "react";
import TeamSection from "../components/TeamSection";
import Topnav from "../components/Topnav";
import { updatePoints, getEvents, getTeams } from "../utils/api";
import { useLoginContext } from "../context/login_context";
import { useState } from "react";
import commonUils from "../utils/common";

const Schedule = () => {
  const { authToken, setTeams } = useLoginContext();
  const [eventName, setEventName] = useState("");

  useEffect(() => {
    const getTeamsData = async () => {
      getEvents(authToken)
        .then((response) => {
          // console.log(response);
          const event_id = response.data.events[0]._id;
          setEventName(response.data.events[0].name);
          getTeams(event_id, authToken)
            .then((response) => {
              // console.log(response.data.teams);
              setTeams(response.data.teams);
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          console.log(error);
          // navigate("/");
        });
      // let response = await getEvents();
      // console.log(response);
    };
    getTeamsData();
  }, []);

  return (
    <>
      <Topnav />
      <div>{eventName} - Schedule</div>
      <TeamSection />
    </>
  );
};

export default Schedule;
