import React from "react";
import Topnav from "../components/Topnav";

const About = () => {
  return (
    <>
      <Topnav />

      <div className="container-how">
        <p>How it works</p>
        <ul className="bullet-points">
          <li>
            This is a virtual sports and truely a guess game based on your pick
            on favourites
          </li>
          <li>
            Choose your winner under schedule based on your knowledge with
            respect to ranking or how they play
          </li>
          <li>
            Choice of Favourite will be closed a day before scheduled Match
          </li>
          <li>
            Points will be awarded based on winner prediction matched with
            actual winner
          </li>
          <li>
            Here is the twist in the fun of this game, if a lower rated team
            beats a higher rated team then you will get difference of ranking as
            points
          </li>
          <li>
            If a higher ranked team beats the lower ranked one, then you will
            win only 1 point
          </li>
          <li>
            Let's say Germany ranked 1 and Scotland ranked 24, if Scotland beats
            Germany then you will get 23 points, if Germany wins as expected
            because of it's rating you will get only 1 point.
          </li>
          <li>Let's start predicting</li>
        </ul>
      </div>
    </>
  );
};

export default About;
