import React, {useEffect, useContext, useReducer, useState} from 'react' 
import reducer from '../reducers/dashboard_reducer'

import {
    CALENDAR_NEXT,
    CALENDAR_PREV,
    CALENDAR_TODAY,
    CALENDAR_LOADING
} from '../actions'

const initialState = {
    curDate: new Date(),
    calType: 'month'
}

const dashboardContext = React.createContext()

export const DashboardProvider = ({children}) => {
    const [state, dispatch] = useReducer(reducer, initialState)
    const next = () => {
        console.log('next')
        dispatch({type: CALENDAR_NEXT,payload: {curDate: state.curDate}})
    }

    const previous = () => {
        console.log('previous')
        dispatch({type: CALENDAR_PREV,payload: {curDate: state.curDate}})
    }

    const today = () => {
        console.log('today')
        dispatch({type: CALENDAR_TODAY})
    }

    return (
        <dashboardContext.Provider value={{...state, next, previous, today}}>
            {children}
        </dashboardContext.Provider>
    )
}

export const useDashboardContext = () => {
    return useContext(dashboardContext)
}