import React, { useState } from "react";
import Menu from "./Menu";

const Topnav = () => {
  const [showMenu, setShowMenu] = useState(false);
  const toggleDropDown = (event) => {
    setShowMenu(!showMenu);
  };
  return (
    <header>
      <nav>
        <div className="logo">
          <div className="logoText">DREAM</div>
          <div className="logoText2">SPORTS</div>
        </div>
        <div className="menu-toggle" id="menu" onClick={toggleDropDown}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <Menu showMenu={showMenu} />
      </nav>
    </header>
  );
};

export default Topnav;
