import React from "react";
import commonUils from "../utils/common";
import { addBet } from "../utils/api";
import { useLoginContext } from "../context/login_context";
import { useScheduleContext } from "../context/schedule_context";
import { useEffect } from "react";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ScheduleCard = (props) => {
  const { authToken, teams } = useLoginContext();
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [msgFailure, setMsgFailure] = useState(false);
  //const { betsChanged, setBetsChanged } = useScheduleContext();
  const {
    _id,
    comp1Id,
    comp2Id,
    scheduleDate,
    stage,
    eventId,
    winnerId,
    bets,
  } = props;

  const [team1ClassName, setTeam1ClassName] = useState(
    "fa fa-thumbs-down thumsUp"
  );

  const [team2ClassName, setTeam2ClassName] = useState(
    "fa fa-thumbs-down thumsUp"
  );

  //"fa fa-thumbs-up thumsUp"

  useEffect(() => {
    const winnerId = getPrediction();
    // console.log(`winnerId = ${winnerId}`);
    changeClass(winnerId);
  }, [bets]);

  useEffect(() => {
    if (showMessage) {
      notify(message, !msgFailure);
      setShowMessage(false);
      setMsgFailure(false);
    }
  }, [showMessage, message, msgFailure]);

  const getTeamDetail = (teamId) => {
    const teamData = teams.find((team) => team._id === teamId);
    if (teamData) {
      return `Bet placed on ${teamData.name} ranked ${teamData.ranking}`;
    } else {
      return "Bet placed";
    }
  };

  const changeClass = (winnerId) => {
    if (winnerId === comp1Id._id) {
      setTeam1ClassName("fa fa-thumbs-up thumsUp");
    } else {
      setTeam1ClassName("fa fa-thumbs-down thumsDown");
    }
    if (winnerId === comp2Id._id) {
      setTeam2ClassName("fa fa-thumbs-up thumsUp");
    } else {
      setTeam2ClassName("fa fa-thumbs-down thumsDown");
    }
  };

  const clickEventHandler = (teamId, scheduleId) => {
    // alert(`you chosen  ${eventId} ${teamId} ${scheduleId}`);
    let placeBet = true;

    if (new Date(scheduleDate) > new Date() && !winnerId) {
      saveBet(eventId, teamId, scheduleId);
      changeClass(teamId);
    } else {
      //alert("Sorry closed, please try for upcoming matches");
      setMessage("Sorry closed, please try for upcoming matches");
      setShowMessage(true);
      setMsgFailure(true);
    }
  };

  const getPrediction = () => {
    const winner = bets.find((team) => team.scheduleId === _id);
    if (winner) {
      return winner.winnerId;
    } else {
      return "";
    }
  };

  const notify = (msg, isSuccess) => {
    if (isSuccess) {
      toast.success(msg);
    } else {
      toast.error(msg);
    }
  };

  const saveBet = (eventId, teamId, scheduleId) => {
    addBet(eventId, teamId, scheduleId, authToken)
      .then((response) => {
        // console.log(response);
        setMsgFailure(false);
        setMessage(getTeamDetail(teamId));
        setShowMessage(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <ToastContainer hideProgressBar={true} theme="colored" />
      <div className="teams" id={commonUils.longDate(new Date(scheduleDate))}>
        <h4>{stage}</h4>
        <h4>{commonUils.formatDate(new Date(scheduleDate))}</h4>
        <div className="team">
          {/* <div>{scheduleDate}</div> */}
          <div className="country">
            <h4>
              {winnerId === comp1Id._id && (
                <i className="fa fa-trophy fa-lg" aria-hidden="true"></i>
              )}
              &nbsp;
              {comp1Id.ranking}
            </h4>
            <img src={comp1Id.flag} alt={comp1Id.name} />
            <h4>{comp1Id.name}</h4>
          </div>
          <i
            className={team1ClassName}
            onClick={() => {
              clickEventHandler(comp1Id._id, _id);
            }}
          ></i>
        </div>

        <div className="team">
          <div className="country">
            <h4>
              {winnerId === comp2Id._id && (
                <i className="fa fa-trophy fa-lg" aria-hidden="true"></i>
              )}
              &nbsp;
              {comp2Id.ranking}
            </h4>
            <img src={comp2Id.flag} alt={comp2Id.name} />
            <h4>{comp2Id.name}</h4>
          </div>
          <i
            className={team2ClassName}
            onClick={() => {
              clickEventHandler(comp2Id._id, _id);
            }}
          ></i>
        </div>
      </div>
    </>
  );
};

export default ScheduleCard;
