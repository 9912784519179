import React from "react";
import { Link } from "react-router-dom";
import { useLoginContext } from "../context/login_context";

const Menu = ({ showMenu }) => {
  const { authToken } = useLoginContext();
  let className = "dropdown";
  if (showMenu) {
    className = "dropdown show";
  } else {
    className = "dropdown hide";
  }
  return (
    <ul id="dropdown" className={className}>
      <li>
        <Link to="/schedule">Schedule</Link>
      </li>
      <li>
        <Link to="/standing">LeaderBoard</Link>
      </li>
      <li>
        <Link to="/about">How it works</Link>
      </li>
      <li>
        <a
          target="_blank"
          href="https://www.uefa.com/euro2024/news/0257-0e13b161b2e8-4a3fd5615e0c-1000--euro-2024-all-you-need-to-know/"
        >
          UEFA Euro 2024
        </a>
      </li>
      {authToken !== "" && (
        <li>
          <Link to="/logout">Logout</Link>
        </li>
      )}
      {/* <li>
        <Link to="/login">Login</Link>
      </li> */}
    </ul>
  );
};

export default Menu;
