import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./app";
import reportWebVitals from "./reportWebVitals";
import { LoginProvider } from "./context/login_context";
import { DashboardProvider } from "./context/dashboard_context";
import { RegisterProvider } from "./context/register_context";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <LoginProvider>
    <DashboardProvider>
      <RegisterProvider>
        <App />
      </RegisterProvider>
    </DashboardProvider>
  </LoginProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
