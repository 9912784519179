import React from "react";

const Card = ({ title, content, titleColor }) => {
  const titleClass = `card-header ${titleColor}`;
  return (
    <div className="card">
      <div className={titleClass}>&nbsp;</div>
      <h2>{title}</h2>
      <div className="card-body">{content}</div>
    </div>
  );
};

export default Card;
