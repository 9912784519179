import React from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLoginContext } from "../context/login_context";

const Logout = () => {
  const navigate = useNavigate();
  const { setAuthToken } = useLoginContext();
  useEffect(() => {
    setAuthToken("");
    localStorage.setItem("sportixToken", "");
    navigate("/");
  }, []);

  return <div>Logging out...</div>;
};

export default Logout;
