import React from "react";
import Card from "./Card";

const InfoCards = ({ arrCards }) => {
  return (
    <div className="card-container">
      {arrCards.map((card) => {
        return (
          <Card
            key={card.id}
            title={card.title}
            content={card.content}
            titleColor={card.titleColor}
          />
        );
      })}
    </div>
  );
};

export default InfoCards;
