import React from "react";

const LeaderCard = (props) => {
  const { userId, point, rank } = props;
  return (
    <>
      {/* <div class="lead-card">
        <h3 class="lead-card-title">#{rank}</h3>
        <p class="lead-card-content">
          {userId.firstName} {userId.lastName}
        </p>
        <h3 class="lead-card-title">{point} Point(s)</h3>
      </div> */}

      <div className="leaders-data">
        <div className="member">#{rank}</div>
        <div className="member">
          {userId.firstName} {userId.lastName}
        </div>
        <div className="member">{point}</div>
      </div>
    </>
  );
};

export default LeaderCard;
