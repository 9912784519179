import React from "react";
import { Link } from "react-router-dom";

const Hero = () => {
  return (
    <div className="hero-text">
      <h1>Play SPORT off the Field</h1>
      <p>May the best bet win.</p>
      <Link to="/register">
        <button className="btn large">Sign up it's free</button>
      </Link>
    </div>
  );
};

export default Hero;
