import {
  LOGIN,
  UPDATE_LOGIN_VALUE,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  RESET_LOGIN_MESSAGE,
  SET_AUTH_TOKEN,
  SET_TEAMS,
  SET_REMEMBER_ME,
} from "../actions";

const login_reducer = (state, action) => {
  // console.log(action.type);
  if (action.type === UPDATE_LOGIN_VALUE) {
    const { name, value } = action.payload;
    return { ...state, [name]: value, loggedIn: false };
  } else if (action.type === LOGIN_SUCCESS) {
    const { loggedIn, message, token, authToken } = action.payload;
    return {
      ...state,
      loggedIn: loggedIn,
      message: message,
      token: token,
      authToken: authToken,
    };
  } else if (action.type === LOGIN_ERROR) {
    // console.log(action.payload);
    const { message } = action.payload;
    return { ...state, loggedIn: false, message: message, token: "" };
  } else if (action.type === RESET_LOGIN_MESSAGE) {
    return { ...state, message: "" };
  } else if (action.type === SET_AUTH_TOKEN) {
    return { ...state, authToken: action.payload.authToken };
  } else if (action.type === SET_TEAMS) {
    return { ...state, teams: action.payload.teams };
  } else if (action.type === SET_REMEMBER_ME) {
    return { ...state, rememberMe: action.payload.rememberMe };
  }

  throw new Error(`No Matching "${action.type}" - action type`);
};

export default login_reducer;
