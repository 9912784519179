import React, { useEffect } from "react";
import ScheduleCard from "./ScheduleCard";
import { getEvents, getSchedules, getBets } from "../utils/api";
import { useNavigate } from "react-router-dom";
import { useLoginContext } from "../context/login_context";
import { useScheduleContext } from "../context/schedule_context";
import { useState } from "react";
import commonUils from "../utils/common";

const Group = (props) => {
  const { group } = props;
  return (
    <div className="group-header-region">
      <h3>{group}</h3>
    </div>
  );
};

const TeamSection = () => {
  const { authToken } = useLoginContext();
  //const { betsChanged } = useSchduleContext();

  const navigate = useNavigate();
  const [filterSchedules, setFilterSchedules] = useState([]);
  const [schedules, setSchedules] = useState([]);
  const [eventId, setEventId] = useState("");
  const [eventName, setEventName] = useState("");
  const [bets, setBets] = useState([]);
  const [search, setSearch] = useState([]);
  const [foption, setFoption] = useState([]);

  const resetFilter = () => {
    setSearch("");
    setFoption("all");
    setFilterSchedules(schedules);
  };

  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  const searchOption = (e) => {
    setFoption(e.target.value);
    setSearch("");
    switch (e.target.value) {
      case "today":
        setFilterSchedules(schedules);
        const scrollId = commonUils.longDate(new Date());
        scrollToSection(scrollId);
        break;
      case "mybets":
        setFilterSchedules(applyFilter(e.target.value));
        break;
      case "pending":
        setFilterSchedules(applyFilter(e.target.value));
        break;
      default:
        setFilterSchedules(schedules);
        break;
    }
  };

  const applyFilter = (type) => {
    if (type === "mybets") {
      return schedules.filter((schedule) => {
        return schedule._id === getBetsOnSchedule(schedule._id, true);
      });
    } else if (type === "pending") {
      return schedules.filter((schedule) => {
        return schedule._id === getBetsOnSchedule(schedule._id, false);
      });
    } else {
      return schedules;
    }
  };

  const getBetsOnSchedule = (scheduleId, exists) => {
    const dataset = bets.find((match) => match.scheduleId === scheduleId);
    if (dataset) {
      return exists ? scheduleId : "";
    } else {
      return exists ? "" : scheduleId;
    }
  };

  const goTop = () => {
    scrollToSection("schedule-top");
  };

  const searchSchedule = (e) => {
    const value = e.target.value;
    setSearch(value);
    const searchStr = value.toLowerCase();
    const filterDataSet = schedules.filter((schedule) => {
      return (
        schedule.comp1Id.name.toLowerCase().includes(searchStr) ||
        schedule.comp2Id.name.toLowerCase().includes(searchStr) ||
        schedule.stage.toLowerCase().includes(searchStr)
      );
    });
    setFilterSchedules(filterDataSet);
  };
  useEffect(() => {
    // console.log(`authToken => ${authToken}`);
    if (authToken === "") {
      navigate("/");
    }
    const getOpenEvent = async () => {
      getEvents(authToken)
        .then((response) => {
          // console.log(response);
          const event_id = response.data.events[0]._id;
          setEventName(response.data.events[0].name);
          getSchedules(event_id, authToken)
            .then((response) => {
              // console.log(response.data.schedules);
              setSchedules(response.data.schedules);
              setFilterSchedules(response.data.schedules);
            })
            .catch((error) => {
              console.log(error);
            });
          getBets(event_id, authToken)
            .then((response) => {
              setBets(response.data.bets);
            })
            .catch((error) => {
              console.log(error);
              //setBets([])
            });
          setEventId(event_id);
        })
        .catch((error) => {
          console.log(error);
          navigate("/");
        });
      // let response = await getEvents();
      // console.log(response);
    };
    getOpenEvent();
    // setBetsChanged(false);
  }, []);

  useEffect(() => {
    const scrollId = commonUils.longDate(new Date());
    scrollToSection(scrollId);
  }, [schedules]);

  return (
    <section className="teamSection" id="schedule-top">
      <i class="fa fa-chevron-up fa-lg float-div" onClick={goTop}></i>
      <Group group={`${eventName} - Schedule`} />
      <h4>
        Place your bet on your favourite team by clicking on thumbs down image
        besides your team.
      </h4>
      <div className="filter-container">
        <input
          type="text"
          id="search"
          name="search"
          value={search}
          required
          autoFocus
          className="input-field"
          onChange={searchSchedule}
          placeholder="Search"
        />
        <select
          className="input-field"
          name="foption"
          id="foption"
          value={foption}
          onChange={searchOption}
        >
          <option value="all">All </option>
          <option value="today">Upcoming</option>
          <option value="mybets">My Bets</option>
          <option value="pending">Pending Bets</option>
        </select>
        <i
          class="fa fa-refresh fa-lg"
          aria-hidden="true"
          aria-label="Reset"
          onClick={resetFilter}
        ></i>
      </div>
      {filterSchedules.map((schedule) => {
        return (
          <ScheduleCard
            key={schedule._id}
            {...schedule}
            bets={bets}
          ></ScheduleCard>
        );
      })}
    </section>
  );
};

export default TeamSection;
