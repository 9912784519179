const commonUils = {
  getMonth: function (curDate) {
    let monthName = curDate.toLocaleString("default", { month: "long" });
    return monthName;
  },
  getDays: function (curDate) {
    return new Date(curDate.getFullYear(), curDate.getMonth() + 1, 0).getDate();
  },

  formatDate: function (date) {
    // console.log(date);
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const padZero = (num) => (num < 10 ? "0" : "") + num;

    const day = padZero(date.getDate());
    const month = months[date.getMonth()];
    const year = String(date.getFullYear()).slice(2);
    const hours = padZero(date.getHours());
    const minutes = padZero(date.getMinutes());

    return `${day}-${month}-${year} ${hours}:${minutes}`;
  },

  longDate: function (date) {
    const padZero = (num) => (num < 10 ? "0" : "") + num;

    const day = padZero(date.getDate());
    const month = padZero(date.getMonth());
    const year = date.getFullYear();
    // const hours = padZero(date.getHours());
    // const minutes = padZero(date.getMinutes());

    return `${year}${month}${day}`;
  },
};

export default commonUils;
