import React from "react";
import { ThreeDots } from "react-loader-spinner";

const Spinner = ({ visible }) => {
  return (
    <div
      style={{
        position: "fixed",
        justifyContent: "center",
        top: "50%",
        left: "50%",
        // opacity: "0.7",
        width: "4rem",
        height: "4rem",
        marginTop: "-2rem" /*set to a negative number 1/2 of your height*/,
        marginLeft: "-2rem" /*set to a negative number 1/2 of your width*/,
        // border: "1px solid #ccc",
      }}
    >
      <ThreeDots
        height="80"
        width="80"
        radius="9"
        color="#4fa94d"
        ariaLabel="three-dots-loading"
        wrapperStyle={{}}
        wrapperClassName=""
        visible={visible}
      />
    </div>
  );
};

export default Spinner;
