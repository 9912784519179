import React, { useEffect, useContext, useReducer, useState } from "react";
import reducer from "../reducers/register_reducer";
import axios from "axios";
import { apiserver } from "../utils/constants";

import {
  REGISTER_STARTED,
  REGISTER,
  REGISTER_ERROR,
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
  UPDATE_REGISTER_VALUE,
  RESET_REGISTER_STATE,
} from "../actions";

const initialState = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  password2: "",
  message: "",
  registered: false,
  register_started: false,
};

const registerContext = React.createContext();

export const RegisterProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const resetStateValues = () => {
    dispatch({
      type: RESET_REGISTER_STATE,
      payload: { savedStatus: false, prof_error: false },
    });
  };

  const setRegisterStarted = (status) => {
    dispatch({
      type: REGISTER_STARTED,
      payload: status,
    });
  };

  const updateInputValue = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    dispatch({ type: UPDATE_REGISTER_VALUE, payload: { name, value } });
  };

  const register = async () => {
    try {
      const url = `${apiserver}/api/v1/auth/register`;
      const response = await axios.post(url, {
        firstName: state.firstName,
        lastName: state.lastName,
        email: state.email,
        password: state.password,
      });
      const data = await response.data;
      if (response.status === 201 || response.status === 200) {
        // console.log("success");
        dispatch({
          type: REGISTER_SUCCESS,
          payload: {
            message: "Account created successfully.",
            registered: true,
            register_started: false,
          },
        });
      } else {
        // console.log(data.msg);
        dispatch({
          type: REGISTER_ERROR,
          payload: { registered: false, register_started: false },
        });
      }
    } catch (error) {
      console.log(error);
      console.log(error.message);
      if (error.code === "ERR_BAD_REQUEST") {
        const data = error.response.data;
        dispatch({
          type: REGISTER_ERROR,
          payload: {
            message: data.msg,
            registered: false,
            register_started: false,
          },
        });
      } else
        dispatch({
          type: REGISTER_ERROR,
          payload: {
            message: error.message,
            registered: false,
            register_started: false,
          },
        });
    }
  };
  return (
    <registerContext.Provider
      value={{
        ...state,
        updateInputValue,
        register,
        resetStateValues,
        setRegisterStarted,
      }}
    >
      {children}
    </registerContext.Provider>
  );
};

export const useRegisterContext = () => {
  return useContext(registerContext);
};
